import { Button, Spinner } from 'reactstrap'

import React from 'react'

// export default function Button() {
//   return <div>hello world</div>
// }

export default function MyButton(props) {
  return (
    <Button
      {...props}
      children={
        props.submitting ? (
          <>
            <Spinner as='span' size='sm' /> Submitting
          </>
        ) : (
          props.children
        )
      }
      disabled={props.submitting ? true : props.disabled}
    />
  )
}
