import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

import React from 'react'

export default class Paginator extends React.Component {
  render() {
    const numberOfPages = Math.ceil(
      this.props.items.length / this.props.itemsPerPage
    )

    const innerPageButtons = new Array(numberOfPages)
      .fill(0)
      .map((zero, index) => {
        return (
          <PaginationItem
            key={this.props.pageNumber}
            active={index === this.props.pageNumber - 1}
          >
            <PaginationLink onClick={() => this.props.callback(index + 1)}>
              {index + 1}
            </PaginationLink>
          </PaginationItem>
        )
      })

    if (numberOfPages < 2) {
      return null
    }

    return (
      <Pagination aria-label='Page navigation example' className='center'>
        <PaginationItem>
          <PaginationLink
            first
            disabled={this.props.pageNumber === 1}
            onClick={() => this.props.callback(1)}
          />
        </PaginationItem>
        <PaginationItem>
          <PaginationLink
            previous
            disabled={this.props.pageNumber === 1}
            onClick={() => this.props.callback(this.props.pageNumber - 1)}
          />
        </PaginationItem>

        {innerPageButtons}

        <PaginationItem>
          <PaginationLink
            next
            disabled={this.props.pageNumber === numberOfPages}
            onClick={() => this.props.callback(this.props.pageNumber + 1)}
          />
        </PaginationItem>
        <PaginationItem>
          <PaginationLink
            last
            disabled={this.props.pageNumber === numberOfPages}
            onClick={() => this.props.callback(numberOfPages)}
          />
        </PaginationItem>
      </Pagination>
    )
  }
}
