import * as EditorTemplatesImport from './editorTemplates'

import ButtonImport from './Button'
import CacheBustingImageImport from './CacheBustingImage'
import PaginatorImport from './Paginator'

export const Button = ButtonImport
export const CacheBustingImage = CacheBustingImageImport
export const Paginator = PaginatorImport
export const EditorTemplates = EditorTemplatesImport
